import { Loader } from "@googlemaps/js-api-loader";

const mapElement = document.querySelector('#map-reseller');

if (mapElement) {
    function createMap(locations) {
        const loader = new Loader ({
            apiKey: "AIzaSyAceO9QUmTZxii1YBVkiyPxyDH_k9gzQ5M",
            version: "weekly",
            libraries: ["marker"]
        });

        let map;
        let markers = []; 
    
        const mapOptions = {
            center: {lat: 50.97377306791086, lng: 2.9431934028415068},
            zoom: 12,
            maxZoom: 12,
            mapId: "3ce102dc7e79ed54",
            mapTypeControl: false,
            streetViewControl: false,
        };
    
        loader 
            .importLibrary('maps', 'marker')
            .then(() => {
                return loader.importLibrary('marker')
            })
            .then (() => {
                if (!google.maps.marker || !google.maps.marker.AdvancedMarkerElement) {
                    console.error("AdvancedMarkerElement is not available.");
                    return;
                }

                const { Map, LatLngBounds } = google.maps;
                const { AdvancedMarkerElement } = google.maps.marker;

                map = new Map(document.getElementById("map-reseller"), mapOptions);

                const bounds = new LatLngBounds();

                locations.forEach(location => {
                    const customMarker = document.createElement('div');
                    customMarker.innerHTML = `
                        <div class="marker-pin"></div>
                    `;

                    const marker = new AdvancedMarkerElement({
                        position: {lat: location.lat , lng: location.lng},
                        map: map,
                        content: customMarker,
                        title: location.title || "Location"
                    });

                    marker.location = location;
                    markers.push(marker);

                    bounds.extend({ lat: location.lat, lng: location.lng });
                });

                map.fitBounds(bounds);

                google.maps.event.addListenerOnce(map, 'bounds_changed', function() {
                    if (map.getZoom() > 12) {
                        map.setZoom(12);
                    }
                });

                const buttons = document.querySelectorAll('.resellers__btn');
                buttons.forEach(button => {
                    button.addEventListener('click', () => {
                        const lat = parseFloat(button.getAttribute('data-lat'));
                        const lng = parseFloat(button.getAttribute('data-lng'));
                        const title = button.getAttribute('data-title');

                        markers.forEach(marker => {
                            if (marker.location.lat === lat && marker.location.lng === lng) {
                                marker.map = map;
                            } else {
                                marker.map = null;
                            };
                        });

                        const selectedLocation = {lat: lat, lng: lng, title: title};
                        map.panTo(selectedLocation);
                        map.setZoom(12);
                    });
                })
            })
            .catch((e) => {
                console.error("Error loading the Google Maps library:", e);
            })
    };

    async function fetchCoordinates() {
        const query = `
            query resellersLocations {
                entries(section: "resellers") {
                    ... on reseller_Entry {
                    title
                    f_address {
                        latitude
                        longitude
                    }
                    }
                }
            }
        `;
    
        const response = await fetch('/api', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer KaxjXJ6bZXmHzZ934sR2V55AyCu5ZP-m`
            },
            body: JSON.stringify({ query })
        });
    
        const result = await response.json();

        return result.data.entries.map(entry => ({
            lat: parseFloat(entry.f_address[0].latitude),
            lng: parseFloat(entry.f_address[0].longitude),
            title: entry.title,
        }));
    };
    
    fetchCoordinates().then(locations => {
        if (locations.length > 0) {
            createMap(locations);
        } else {
            console.error("No location found");
        }
    });

};


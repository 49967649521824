// core version + navigation, pagination modules:
import Swiper from 'swiper';
import { Navigation, Pagination, Autoplay, FreeMode, EffectFade } from 'swiper/modules';
// import Swiper and modules styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

const companiesEl = document.querySelector('.companySlider');
const imagesEl = document.querySelector('.sliderImages');
const textEl = document.querySelector('.sliderText');
const blogEl = document.querySelector('.blog__lightBox');

if (textEl) {
    const swiperTextImage = new Swiper('.sliderText__slider', { 
        modules: [Navigation, Pagination],
        slidesPerView: 1,
        loop: true,
        pagination: {
            el: '.swiper-pagination',
            clickable: true,
        },
        navigation: {
        nextEl: '.sliderText__btn--next',
        prevEl: '.sliderText__btn--prev',
        
    },
    });
};

if (companiesEl) {
    const swiperCompany = new Swiper('.companySlider__slider', {
        modules: [Autoplay, FreeMode],
        slidesPerView: 5,
        direction: 'horizontal',
        spaceBetween: 30,
        loop: true,
        freeMode: true,
        speed: 3000,
        autoplay: {
            delay: 0,
            disableOnInteraction: false,
        },
        breakpoints: {
            1024: {
                slidesPerView: 6,
                spaceBetween: 100,
            },
            1280: {
                slidesPerView: 8,
                spaceBetween: 100,
            },
            1440: {
                slidesPerView: 8,
                spaceBetween: 130,
            },
        }
    });
};

if (imagesEl) {
    const swiper = new Swiper('.sliderImages__slider', {
        modules: [Navigation, Pagination],
        slidesPerView: 1,
        loop: true,
        spaceBetween: 20,
        pagination: {
            el: '.swiper-pagination',
            clickable: true,
        },
        navigation: {
            nextEl: '.sliderImages__btn--next',
            prevEl: '.sliderImages__btn--prev',
        },
        breakpoints: {
            1024: {
                slidesPerView: 2.5,
                spaceBetween: 10,
            }
        }
    });
};

if (blogEl) {
    const swiperBlog = new Swiper('.blog__sliderBox', {
        modules: [Navigation, Pagination],
        slidesPerView: 1,
        centeredSlides: true,
        pagination: {
            el: '.swiper-pagination',
            clickable: true,
        },
        navigation: {
        nextEl: '.blog__btn--next',
        prevEl: '.blog__btn--prev',
        },
    });
}


import Chart from 'chart.js/auto';
const chartElement = document.querySelector('.graph');

if (chartElement) {
  document.addEventListener('DOMContentLoaded', () => {
    const graphqlEndpoint = '/api';
    const query = `
          query GraphQuery {
            entries(section: "graph") {
              ... on graph_Entry {
                title
                f_graphData {
                  z
                }
                f_graphData2 {
                  z
                }
                f_graphData3 {
                  z
                }
                f_rms
                f_rmsMin
                f_rms2
                f_rms2Min
                f_rms3
                f_rms3Min
              }
            }
          }`;
    
    let myChart;
    let selected = 3; 
    
    async function fetchGraphData() {
        try {
          const response = await fetch(graphqlEndpoint, {
              method: 'POST',
              headers: {
                  'Content-Type': 'application/json',
                  'Accept': 'application/json',
                  'Authorization': `Bearer KaxjXJ6bZXmHzZ934sR2V55AyCu5ZP-m`
              },
              body: JSON.stringify({ query })
          });
  
          if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
          }
  
          const result = await response.json();
  
          return result.data.entries[0];
        } catch (error) {
          console.error("Error fetching graph data", error);
          throw error;
        }
  
    }
    
    function extractZValues(data) {
        return data.map(row => row.z);
    }
    
    function createChart(chartData, chartMax, chartMin) {
      var ctx = document.getElementById('myGraph').getContext('2d');
  
      if (!ctx) {
        console.error("chart element not found");
        return;
      }
    
      const dataMain = chartData;
      const dataMax = Array(chartData.length).fill(chartMax);
      const dataMin = Array(chartData.length).fill(chartMin);
  
      const labels = [];
      
      for (let i = 0; i <= 240; i += 5) {
        labels.push(i);
      }
    
      myChart = new Chart(ctx, {
        type: 'line', 
        data: {
            labels: labels,
            datasets: [{
                label: 'Main',
                data: dataMain,
                backgroundColor: 'rgba(255, 255, 255, 0)',
                borderColor: 'rgba(176, 198, 251, 1)',
                borderWidth: 2.5
            },
            {
              label: 'Max',
              data: dataMax, 
              backgroundColor: 'rgba(255, 255, 255, 0)',
              borderColor: 'rgba(113, 224, 213, 1)',
              borderWidth: 2.5,
              borderDash: [3, 2.5],
            },
            {
              label: 'Min',
              data: dataMin, 
              backgroundColor: 'rgba(255, 255, 255, 0)',
              borderColor: 'rgba(113, 224, 213, 1)',
              borderWidth: 2.5,
              borderDash: [3, 2.5],
            }
          ]
        },
        options: {
            responsive: true,
            pointStyle: false,
            tension: 0.4,
            scales: {
                x: {
                    beginAtZero: true,
                    min: 0,
                    max: 240,
                    ticks: {
                        color: 'rgba(255, 255, 255, 1)',
                        callback: function(value, index) {
                          return index % 4 === 0 ? this.getLabelForValue(value) : '';
                        }
                    },
                    grid: {
                        display: true,
                        lineWidth: 1,
                        tickLength: 8,
                        color: function(context) {
                          if (context.tick && context.tick.label !== '' && context.index % 4 === 0) {
                            return 'rgba(255, 255, 255, 0.3)';
                          }
                          return 'rgba(0, 0, 0, 0)';
                        }
                    },
                    border: {
                        color: 'rgba(255, 255, 255, 1)',
                        lineWidth: 2,
                    },
                    suggestedMin: 0,
                    suggestedMax: 240,
                },
                y: {
                    min: -4,
                    max: 4,
                    ticks: {
                        color: 'rgba(255, 255, 255, 1)',
                        stepSize: 1
                    },
                    grid: {
                        display: true,
                        color: 'rgba(255, 255, 255, 0.3)',
                        lineWidth: 1,
                        tickLength: 8,
                    },
                    border: {
                        color: 'rgba(255, 255, 255, 1)',
                        lineWidth: 2,
                    },
                },
            },
            animation: {
                duration: 500,
                easing: 'easeInOutQuad', 
                animateScale: true,
                animateRotate: true,
            },
            plugins: {
            legend: {
                display: false,
            }
        }
        }
      });
    }
    
    function updateChart(element, number, chartData, chartMax, chartMin) {
      element.addEventListener('click', () => {
        if ( selected == number ) {
          selected = 0;
          myChart.data.datasets[0].data = 0;
          myChart.data.datasets[1].data = 0;
          myChart.data.datasets[2].data = 0;
          myChart.update();
    
        } else {
          selected = number;
          const dataMain = chartData;
          const dataMax = Array(chartData.length).fill(chartMax);
          const dataMin = Array(chartData.length).fill(chartMin);
      
          myChart.data.datasets[0].data = dataMain;
          myChart.data.datasets[1].data = dataMax;
          myChart.data.datasets[2].data = dataMin;
          myChart.update();
        }
      });
    }
    
    fetchGraphData().then(data => {
        const graphDataArray = extractZValues(data.f_graphData);
        const graphData2Array = extractZValues(data.f_graphData2);
        const graphData3Array = extractZValues(data.f_graphData3);
        const graphMax = data.f_rms;
        const graph2Max = data.f_rms2; 
        const graph3Max = data.f_rms3; 
        const graphMin = data.f_rmsMin;
        const graph2Min = data.f_rms2Min; 
        const graph3Min = data.f_rms3Min; 
  
        createChart(graphDataArray, graphMax, graphMin);
    
        const switchOne = document.querySelector('#toggleOne');
        const switchTwo = document.querySelector('#toggleTwo');
        const switchThree = document.querySelector('#toggleThree');
    
        updateChart(switchOne, 1, graphData3Array, graph3Max, graph3Min);
        updateChart(switchTwo, 2, graphData2Array, graph2Max, graph2Min);
        updateChart(switchThree, 3, graphDataArray, graphMax, graphMin);
    }).catch(error => {
      console.error("failed to fetch and create chart", error)
    })
  });
};


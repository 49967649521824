import anime from 'animejs/lib/anime.es.js';

document.addEventListener('DOMContentLoaded', () => {
    anime({
        targets: '.hero__titleWord',
        translateY: ['5rem', 0],
        rotate: [5, 0],
        opacity: [0, 1],
        delay: anime.stagger(30, { start: 500 }),
        easing: 'easeOutCubic',
        duration: 550    
    });
});
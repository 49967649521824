const btnText = document.querySelector('.header__btnText');
const logoWord = document.querySelector('.header__logoWordBox');
const logoIcon = document.querySelector('.header__logoIcon')

window.addEventListener('scroll', () => {
    const currentScroll = window.scrollY;

    if (currentScroll > 100) {
        btnText.classList.add('header__btnText--hide');
        logoWord.classList.add('header__logoWordBox--hide');
        logoIcon.classList.add('header__logoIcon--smaller');
    } else {
        btnText.classList.remove('header__btnText--hide');
        logoWord.classList.remove('header__logoWordBox--hide');
        logoIcon.classList.remove('header__logoIcon--smaller');
    };
})